// ExperimentDetail.jsx
import React, { useEffect, useRef, useState } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import { FaFilePdf } from 'react-icons/fa';
import apiService from "../../services/apiService";
import AppConfig from "../../AppConfig";

/*
    Gene Synthesis component is used to add/edit fields sequence to be synthesized ,
    date of order , date of reciept , company , gene synthesis pdfs for selected selectedExperiment of a product
*/
const GeneSynthesis = ({
    selectedExperiment,
    setSelectedExperiment,
    handleCloseGeneSynthesisModal,
}) => {
    const pdfInputRef = useRef(null);
    const [experiment, setExperiment] = useState(selectedExperiment ?? {});
    const [experimentGenePdfs, setExperimentGenePdfs] = useState(selectedExperiment?.gene_synthesis_pdfs ?? []);
    const [pdfsToUpload, setPdfsToUpload] = useState([]);
    const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
    const [pdfPreviews, setPdfPreviews] = useState([]);


    useEffect(() => {
        //..console.log("experiment", experiment);
    }, [experiment]);

    useEffect(() => {
        return () => {
            pdfPreviews.forEach((preview) => URL.revokeObjectURL(preview.url));
        };
    }, [pdfPreviews]);

    const handleInputChange = (chObj) => {
        setExperiment(e => { return { ...e, ...chObj } })
    }

    const selectedOpenPdf = (pdf) => {
      const pdfUrl = pdfPreviews.find((preview) => preview.name === pdf)?.url
        ?? `${AppConfig.baseUrl}/uploads/${pdf}`;
        setSelectedPdfUrl(pdfUrl);
    };


    const handleDeletePdf = (pdf) => {

        setExperimentGenePdfs(e => e.filter(item => item !== pdf));
        setPdfsToUpload((prev) =>
            prev.filter((file) => file.name !== pdf)
        );
        setPdfPreviews((prev) =>
            prev.filter((preview) => preview.name !== pdf)
        );
    }

    const handleCancel = () => {
        handleCloseGeneSynthesisModal();

    }

    const handleSave = async () => {
        if (pdfsToUpload.length > 0) {
            try {
                const formData = new FormData();
                pdfsToUpload.forEach((file) => formData.append("genePdfs", file));
                await apiService.post(`/genePdfs/upload/${selectedExperiment.id}`, formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                setPdfsToUpload([]);
            } catch (error) {
                console.error("Error uploading pdfs:", error);
                alert("Failed to upload files. Please try again.");
            }
        }
        //..console.log("experiment in save", experiment);
        //..console.log("Experiment pdfs in save", experimentGenePdfs);


        setSelectedExperiment({ ...experiment, gene_synthesis_pdfs: experimentGenePdfs });
        handleCloseGeneSynthesisModal();
    };
    const handlePdfBrowseClick = () => {
        pdfInputRef.current.click();
    }
    const handlePdfFileChange = (e) => {
        const _pdfsToUpload = Array.from(e.target.files);
        const allowedFiles = _pdfsToUpload.filter(
            (file) =>
                file.type === "application/pdf"
        );
        if ((allowedFiles.length + experimentGenePdfs.length) > 10) {
            alert("Maximum 10 files allowed.");
            return;
        }
        const renamedFiles = allowedFiles.map((file, index) => {
            const newName = `exp_gsynthesis_${experiment.id}_${experimentGenePdfs.length + index + 1}.pdf`;
            return new File([file], newName, { type: file.type });
        });
        const renamedFileNames = renamedFiles.map((file) => file.name);

        const newPreviews = renamedFiles.map((file) => ({
            name: file.name,
            url: URL.createObjectURL(file),
        }));
        setExperimentGenePdfs([...experimentGenePdfs, ...renamedFileNames]);
        setPdfsToUpload((prevFiles) => [...prevFiles, ...renamedFiles]);
        setPdfPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);


    }

    return (
        <>
            {experiment && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg w-1/2 max-h-screen overflow-y-auto">
                        <div className="mb-4">
                            <label htmlFor="sequenceToBeSynthesized" className="block text-black-700 font-bold mb-1">
                                Find Sequence to be Synthesized:
                            </label>
                            <textarea
                                id="sequence_to_be_synthesized"
                                name="sequence_to_be_synthesized"
                                value={experiment.sequence_to_be_synthesized ?? ""}
                                onChange={(e) => {
                                    handleInputChange({ sequence_to_be_synthesized: e?.target?.value });
                                }}
                                className="border border-gray-300 p-2 rounded w-full"
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="company" className="block text-black-700 font-bold mb-1">
                                Company:
                            </label>
                            <select
                                id="company"
                                name="company"
                                value={experiment.company ?? ""}
                                onChange={(e) => {
                                    handleInputChange({ company: e?.target?.value });
                                }}
                                className="border border-gray-300 p-2 rounded w-full"
                            >
                                <option value="">Select Company</option>
                                <option value="Eurofins">Eurofins</option>
                                <option value="Twist Biosciences">Twist Biosciences</option>
                            </select>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="dateOfOrder" className="block text-black-700 font-bold mb-1">
                                Date of Order:
                            </label>
                            <input
                                type="date"
                                id="date_of_order"
                                name="date_of_order"
                                value={experiment.date_of_order ?? ""}
                                onChange={(e) => {
                                    handleInputChange({ date_of_order: e?.target?.value });
                                }}
                                className="border border-gray-300 p-2 rounded w-full"
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="dateOfReceipt" className="block text-black-700 font-bold mb-1">
                                Date of Receipt:
                            </label>
                            <input
                                type="date"
                                id="date_of_receipt"
                                name="date_of_receipt"
                                value={experiment.date_of_receipt ?? ""}
                                onChange={(e) => {
                                    handleInputChange({ date_of_receipt: e?.target?.value });
                                }}
                                className="border border-gray-300 p-2 rounded w-full"
                            />
                        </div>
                        <div className='mb-4'>

                            <div>
                                <label htmlFor="attachfile" className="block font-bold mb-1 mr-4">
                                    Quality Assessment Record:
                                </label>
                                <button
                                    type="button"
                                    onClick={handlePdfBrowseClick}
                                    className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
                                >
                                    Upload PDFs
                                </button>
                                <input
                                    type="file"
                                    id="pdfUpload"
                                    name="pdfUpload"
                                    ref={pdfInputRef}
                                    onChange={e => handlePdfFileChange(e)}
                                    className="hidden"
                                    accept="application/pdf"
                                    multiple
                                />
                            </div>
                            <div>
                                <div className="my-3 flex flex-wrap">
                                    <div className="flex flex-wrap gap-2 mt-2">
                                        {experiment && experimentGenePdfs.length > 0 &&
                                            experimentGenePdfs.map((pdf, index) => (
                                                <div key={index} className="flex items-center mb-2">
                                                    <FaFilePdf className="text-red-500 mr-2" />
                                                    <span className="underline cursor-pointer" onClick={() => selectedOpenPdf(pdf)}>
                                                        {pdf}
                                                    </span>
                                                    <button
                                                        type="button"
                                                        className="bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                                        onClick={() => handleDeletePdf(pdf)}
                                                    >
                                                        <IoCloseOutline />
                                                    </button>
                                                </div>
                                            ))}
                                            
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* Actions */}
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={handleSave}
                                className="p-2 w-24 font-bold text-white rounded"
                                style={{ background: '#7FB541', borderColor: 'transparent' }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {/* PDF Viewer Modal */}
            {selectedPdfUrl && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg w-3/4 max-h-screen overflow-y-auto">
                        <iframe
                            src={selectedPdfUrl}
                            className="w-full h-96"
                            title="PDF Viewer"
                        ></iframe>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setSelectedPdfUrl(null)}
                                className="bg-gray-500 text-white py-2 px-4 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </>
    );

};

export default GeneSynthesis;

