
import React, { useState, useEffect } from "react";
import AddProductForm from "./AddProductForm";
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "../redux/usersSlice";
import { setProducts } from "../redux/productsSlice";
import EditProductForm from "./EditProductForm";
import apiService from "../services/apiService";
import ImageLoader from "./ImageLoader";
import Pagination from "../utils/pagination";
import AppConfig from "../AppConfig";
import ImageSlider from "./ImageSlider"; // Import ImageSlider component
import Footer from "./Footer";
import { Link, useNavigate } from 'react-router-dom';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { PiTrash } from "react-icons/pi";
import { toast } from 'sonner';
import { deleteProduct } from "../redux/productsSlice";
import { random } from "nanoid";



const ProductPage = () => {
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false); // State to control modal visibility
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productPhotos, setProductPhotos] = useState([]); // State to store product photos
  const [productPdfs, setProductPdfs] = useState([]); // State to store product PDFs
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.users.user);
  const users = useSelector((store) => store.users);
  const products = useSelector((store) => store.products);
 // const [isOptionsVisible, setOptionsVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control delete confirmation modal
  const [optionsVisibility, setOptionsVisibility] = useState({}); // State to control options visibility for each product
  const [productToDelete, setProductToDelete] = useState(null); // State to store the product to delete
  



  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await apiService.get("/users/charaka");
        dispatch(setUsers(response.data));
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsersData();
  }, [dispatch]);

  const fetchProductsData = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem('userId');
      // if CORS error occurs uncomment line no 61 and comment 62 along with change in App.js
      //const response = await apiService.get(`/products`);
      const response = await apiService.get(`/productsWithAccess/userId/${userId}`);
      if (!Array.isArray(response.data)) {
        console.error("Expected an array but received:", response);
        return;
      }

      const productsWithPhotosAndPDFs = await Promise.all(
        response.data.map(async (product) => {
          try {
            const photosResponse = await apiService.get(`/photos/${product.id}`);
            const pdfsResponse = await apiService.get(`/pdfs/${product.id}`);

            return { ...product, photos: photosResponse.data, pdfs: pdfsResponse.data };
          } catch (error) {
            console.error(`Error fetching photos for product ${product.id}:`, error);
            return { ...product, photos: [], pdfs: [] };
          }
        })
      );
      dispatch(setProducts(productsWithPhotosAndPDFs));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductsData();
  }, [dispatch]);



  const handleAddClick = () => {
    navigate('/add');
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleEditClick = (product) => {
    //..console.log(product, "product")
    setSelectedProduct(product);
    setProductPhotos(product.photos);
    setProductPdfs(product.pdfs);
    navigate(`/edit/${product.id}`, { state: { product, productPhotos: product.photos, productPdfs: product.pdfs } });
  };

  // const handleEditClick = (product) => {
  //   setSelectedProduct(product);
  //   setProductPhotos(product.photos);
  //   setProductPdfs(product.pdfs);
  // };

  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    setProductPhotos([]);
    setProductPdfs([]);
  };
  const onProductAdded = () => {
    fetchProductsData(); // Fetch products after adding a new product
  };
  const onProductEdited = () => {
    fetchProductsData(); // Fetch products after editing a product
  };

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const toggleOptions = () => {
    // setOptionsVisible(!isOptionsVisible);
  // };
  const toggleOptions = (productId) => {
    setOptionsVisibility((prevVisibility) => ({
      ...prevVisibility,
      [productId]: !prevVisibility[productId],
    }));
  };
  
  const handleOpenDeleteModal = (product) => {
    setProductToDelete(product); // Set the selected product to delete
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setProductToDelete(null); // Clear the selected product when closing the modal
  };
  
  const handleDeleteProduct = async () => {
    if (!productToDelete) return; // Check if there's a product to delete

    try {
      // Make an HTTP DELETE request to delete the product

      await apiService.delete(`/product/delete/${productToDelete.id}`);

      // Handle success, e.g., close the modal and update UI
      dispatch(deleteProduct(productToDelete.id));
      toast.success('Product deleted successfully');
      // onClose();
      setShowDeleteModal(false); // Close the modal after deletion
      setProductToDelete(null); // Clear the selected product after deletion
      fetchProductsData(); // Refresh the product list
      navigate('/productPage');
    } catch (error) {
      // Handle error
      toast.error('failed to delete the product');
      console.error("Error deleting product:", error);
    }
  };

  return (
    <><div className="max-w-7xl mx-auto" style={{ minHeight: '90vh' }}>
      <div className="w-full flex justify-between my-8">
        <h1 className="text-3xl font-bold"> All Products </h1>
        <button
          onClick={handleAddClick}
          className="p-2 w-24 font-bold text-white rounded"
          style={{
            background: '#7FB541',
            borderColor: 'transparent',
          }}
        >
          Add
        </button>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <ul className="grid grid-cols-5 gap-5">
            {currentProducts.map((product) => (
              <li

                key={product.id + random(2)}
                className="border cursor-pointer flex flex-col justify-between border-gray-300 hover:shadow-md rounded p-4 my-4"
              >
                <div>
                  <div className="flex items-center justify-between relative">
                    {/* Product Name */}
                    <h2
                      className="text-xl font-semibold cursor-pointer"
                      onClick={() => handleEditClick(product)}
                    >
                      {product.name}
                    </h2>
                    {/* Options Button */}
                    <div className="relative">
                      <button
                        type="button"
                        // onClick={toggleOptions}
                        onClick={() => toggleOptions(product.id)}  // Pass product ID to toggle function

                        className="text-gray-600 hover:text-gray-800 flex items-center justify-center"
                      >
                        <BiDotsVerticalRounded size={20} />
                      </button>
                      {/* Options Dropdown */}
                      {/* {isOptionsVisible && ( */}
                      {optionsVisibility[product.id] && ( 
                        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-50">
                          <button
                            type="button"
                            onClick={() => handleOpenDeleteModal(product)} // Pass product to the delete modal handler
                            className="w-full px-4 py-2 text-left text-gray-600 hover:text-red-600 hover:bg-gray-100 flex items-center"
                          >
                            <PiTrash className="mr-2 h-4 w-4" />
                            <span>Delete Product</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>


                  <p className="text-gray-500">{product.stage}</p>
                  <p className="text-gray-500">
                    {users
                      .filter((user) => user.id === product.team_lead)
                      .map((user) => user.name)}
                  </p>
                  <div className="mt-4">
                    {product.photos && product.photos.length > 0 ? (
                      <ImageSlider
                        imagesList={product.photos.map(photo => ({
                          url: `${AppConfig.baseUrl}/${photo}`,
                          id: photo
                        }))} />
                    ) : (
                      <ImageLoader name="image-not-available.jpg" />
                    )}
                  </div>

                </div>
              </li>
            ))}
          </ul>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={products.length}
            currentPage={currentPage}
            onPageChange={handlePageChange} />
        </div>
      )}
      {/* {showForm && <AddProductForm onClose={handleCloseForm} onProductAdded={onProductAdded} />} */}
      {/* {selectedProduct && (
        <EditProductForm
          product={selectedProduct}
          productPhotos={productPhotos}
          productPdfs={productPdfs}
          onClose={handleCloseEditModal}
          onProductEdited={onProductEdited} />
      )} */}
      {/* Delete confirmation modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-md max-h-svh overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-4">Confirm Deletion</h2>
            <p className="mb-4">
              Are you sure you want to delete this product?
            </p>
            <div className="flex justify-end">
              <button
                onClick={handleCloseDeleteModal}
                className="mr-4 bg-gray-400 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteProduct}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
      <Footer></Footer>
    </>
  );
};
export default ProductPage;