// ExperimentDetail.jsx
import React from 'react';

const ExperimentDetail = ({

    flag,
    currentExperimentNameAdd,
    currentCommonNameAdd,
    currentRowIdAdd,
    handleInputChangeAdd,
    //gene_sequence,
    //protein_sequence,
    geneSequenceAdd,
    handleCloseExperimentDetailModalAdd,
    handleSaveDetailModalAdd,
    editedProduct,
    selectedExperiment,
    setEditedProduct,
    setSelectedExperiment,
    handleCloseExperimentDetailModal,
    handleSaveDetailModal,
}) => {
    
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-lg w-1/2">
                <h2 className="text-xl font-bold mb-4">{flag === 0 ? currentExperimentNameAdd : selectedExperiment.experiment_name}</h2>

                {/* Experiment Name */}
                <div className="mb-4">
                    <label htmlFor="experimentName" className="block text-black-700 font-bold mb-1">
                        Experiment Name:
                    </label>
                    <input
                        type="text"
                        id="experimentName"
                        name="experimentName"
                        value={flag === 0 ? currentExperimentNameAdd : selectedExperiment.experiment_name}
                        onChange={(e) => {
                            if (flag === 1) {
                                const updatedExperiments = editedProduct.product_experiments.map((exp) => {
                                    if (exp.id === selectedExperiment.id) {
                                        return { ...exp, experiment_name: e.target.value };
                                    }
                                    return exp;
                                });
                                setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
                                setSelectedExperiment((prev) => ({ ...prev, experiment_name: e.target.value }));
                            }
                        }}
                        className="border border-gray-300 p-2 rounded w-full"
                        required
                        readOnly={flag === 0}
                    />

                </div>

                {/* Common Name */}
                <div className="mb-4">
                    <label htmlFor="commonName" className="block text-black-700 font-bold mb-1">
                        Common Name:
                    </label>
                    <input
                        type="text"
                        id="commonName"
                        name="commonName"
                        value={flag === 0 ? currentCommonNameAdd : selectedExperiment.common_name ?? ""}
                        onChange={(e) => {
                            if (flag === 1) {
                                const updatedExperiments = editedProduct.product_experiments.map((exp) => {
                                    if (exp.id === selectedExperiment.id) {
                                        return { ...exp, common_name: e.target.value };
                                    }
                                    return exp;
                                });
                                setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
                                setSelectedExperiment((prev) => ({ ...prev, common_name: e.target.value }));
                            }
                        }}
                        className="border border-gray-300 p-2 rounded w-full"
                        required
                        readOnly={flag === 0}

                    />
                </div>

                {/* Gene  sequence*/}
                <div className="mb-4">
                    <label htmlFor="gene" className="block text-black-700 font-bold mb-1">
                        Gene Sequence:
                    </label>
                    <textarea
                        id="gene_sequence"
                        name="gene_sequence"
                        value={flag === 0 ? geneSequenceAdd : selectedExperiment.gene_sequence ?? ""}
                        /*onChange={(e) => {
                            const updatedExperiments = editedProduct.product_experiments.map((exp) => {
                                if (exp.id === selectedExperiment.id) {
                                    return { ...exp, gene_sequence: e.target.value };
                                }
                                return exp;
                            });
                            setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
                            setSelectedExperiment((prev) => ({ ...prev, gene_sequence: e.target.value }));
                        }}*/
                        readOnly // Make the field non-editable
                        className="border border-gray-300 p-2 rounded w-full"
                        placeholder="No Gene Sequence Found"
                    />
                </div>

                {/* Protein Sequence */}
                <div className="mb-4">
                    <label htmlFor="protein" className="block text-black-700 font-bold mb-1">
                        Protein Sequence:
                    </label>
                    <textarea
                        id="protein_sequence"
                        name="protein_sequence"
                        value={flag === 0 ? currentRowIdAdd.protein_sequence : selectedExperiment.protein_sequence ?? ""}
                        onChange={(e) => {
                            if (flag === 1) {
                                const updatedExperiments = editedProduct.product_experiments.map((exp) => {
                                    if (exp.id === selectedExperiment.id) {
                                        return { ...exp, protein_sequence: e.target.value };
                                    }
                                    return exp;
                                });
                                setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
                                setSelectedExperiment((prev) => ({ ...prev, protein_sequence: e.target.value }));
                            }
                            else {
                                //..console.log(currentRowIdAdd);
                                handleInputChangeAdd(currentRowIdAdd, e)
                            }

                        }
                        }
                        className="border border-gray-300 p-2 rounded w-full"
                        placeholder="Enter protein sequence"
                    />
                </div>


                {/* Actions */}
                <div className="flex justify-end">
                    <button
                        type="button"
                        className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                        onClick={flag === 0 ? handleCloseExperimentDetailModalAdd : handleCloseExperimentDetailModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={flag === 0 ? handleSaveDetailModalAdd : handleSaveDetailModal}
                        className="p-2 w-24 font-bold text-white rounded"
                        style={{ background: '#7FB541', borderColor: 'transparent' }}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>

    );
};

export default ExperimentDetail;
