import React, { useEffect, useRef } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import ImageSlider from "../ImageSlider";
import AppConfig from "../../AppConfig";
import { useState } from 'react';
import apiService from "../../services/apiService";
/*
    Virtual Clone Construct component is used to add/edit fields vectors , 
    clone construct images , clone size, clone siting for selected experiment of a product
*/
const VirtualCloneConstruct = ({
    handleCloseVirtualCloneConstructModal,
    selectedExperiment,
    setSelectedExperiment,
}) => {

    const fileInputRef = useRef(null);
    const [experiment, setExperiment] = useState(selectedExperiment ?? {});
    const [experimentCloneImages, setExperimentCloneImages] = useState(selectedExperiment?.clone_images ?? []);
    const [filesToUpload, setFilesToUpload] = useState([]);

    useEffect(() => {
        //..console.log("experiment", experiment);
    }, [experiment]);

    //To handle changes in the virtual clone construct input fields
    const handleInputChange = (chObj) => {
        setExperiment(e => { return { ...e, ...chObj } })

    }
    const getObjUrl = (obj) => {
        if (obj)
            return URL.createObjectURL(obj);
        else
            return null;
    }

    //Delete item at the given index from selected experiments.clone_images
    const handleDeleteFile = (photo) => {
        //..console.log("photo", photo, "experiment clone Images", experimentCloneImages);
        setFilesToUpload((prev) =>
            prev.filter((file) => file?.name !== photo)
        );
        setExperimentCloneImages(e => e.filter(item => item !== photo));

    }

    //to close the form
    const handleCancel = () => {
        handleCloseVirtualCloneConstructModal();

    }

    //To save the form details
    const handleSave = async () => {
        if (filesToUpload.length > 0) {
            try {
                const formData = new FormData();
                filesToUpload.forEach((file) => formData.append("cloneImages", file));
                await apiService.post(`/cloneImages/upload/${selectedExperiment.id}`, formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                setFilesToUpload([]);
            } catch (error) {
                console.error("Error uploading files:", error);
            }
        }
        //..console.log("experiment in save", experiment);
        //..console.log("experimentCloneImages in save", experimentCloneImages);


        setSelectedExperiment({ ...experiment, clone_images: experimentCloneImages });
        handleCloseVirtualCloneConstructModal();
    };
    //To add images
    const handleBrowseClick = () => {
        fileInputRef.current.click();
    }
    //To handle changes in the images 
    const handleFileChange = (e) => {
        const _filesToUpload = Array.from(e.target.files);
        // Filter out files that are not jpeg, png, or webp
        const allowedFiles = _filesToUpload.filter(
            (file) =>
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/webp"
        );
        // Limit the number of files to a maximum of 10
        if ((allowedFiles.length + experimentCloneImages.length) > 10) {
            alert("Maximum 10 files allowed.");
            return;
        }
        const renamedFiles = allowedFiles.map((file, index) => {
            const newName = `exp_vclone_${experiment.id}_${experimentCloneImages.length + index + 1}.jpg`;
            return new File([file], newName, { type: file.type });
        });
        const renamedFileNames = renamedFiles.map((file) => file.name);
        setExperimentCloneImages([...experimentCloneImages, ...renamedFileNames]);
        setFilesToUpload((prevFiles) => [...prevFiles, ...renamedFiles]);

    }

    const containerStyles = {
        width: "300px",
        height: "280px",
        margin: "0 auto",
    };


    return (experiment &&

        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-lg w-1/2 max-h-screen overflow-y-auto">

                <div className="mb-4">
                    <label htmlFor="vectors" className="block text-black-700 font-bold mb-1">
                        Vectors:
                    </label>
                    <input
                        type="text"
                        id="vectors"
                        name="vectors"
                        value={experiment.vectors}
                        onChange={(e) => {
                            handleInputChange({ vectors: e?.target?.value });
                        }}
                        className="border border-gray-300 p-2 rounded w-full"
                    />

                </div>

                <div className="mb-4">
                    <label htmlFor="imageConstructUpload" className="block text-black-700 font-bold mb-1">
                        Image Construct Upload:
                    </label>
                    <button
                        type="button"
                        onClick={handleBrowseClick}
                        className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
                    >
                        Upload Images
                    </button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept=".jpg,.png,.webp"
                        multiple
                        onChange={e => handleFileChange(e)}
                        className="hidden"
                    />
                    <div className="mb-4">

                        <div>

                            {(!experimentCloneImages || experimentCloneImages.length === 0 || experimentCloneImages == null) && (
                                <p className="text-green-700 text-xs" style={{ color: "#7FB541" }}>
                                    Up to 10 files are allowed to be uploaded with the maximum file size
                                    of 20MB per file. Extensions allowed are .jpeg, .png, and .webp.
                                </p>
                            )}

                            {(experimentCloneImages.length > 0) && (
                                <div
                                    style={{
                                        ...containerStyles,
                                        height: experimentCloneImages.length > 0 ? "auto" : 0,
                                    }}
                                >
                                    <ImageSlider
                                        imagesList={[
                                            ...experimentCloneImages.map((cloneImageUrl) => ({
                                                url: getObjUrl(filesToUpload.find((i => i?.name == cloneImageUrl))) ?? `${AppConfig.baseUrl}/uploads/${cloneImageUrl}`,

                                            })),

                                        ]}
                                    />

                                </div>
                            )}
                            <div className="flex flex-wrap gap-2 mt-2">
                                {experiment && experimentCloneImages.length > 0 &&
                                    experimentCloneImages.map((photo, index) => (
                                        <div key={index} className="relative">
                                            {
                                                <img
                                                    src={`${AppConfig.baseUrl}/uploads/${photo}`}
                                                    onError={(e) => {
                                                        e.target.src = URL.createObjectURL(filesToUpload.find((i => i?.name == photo))) ?? ""
                                                    }}
                                                    className="w-20 h-20 object-cover"
                                                />}
                                            <button
                                                type="button"
                                                className=" absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                                onClick={() => handleDeleteFile(photo)}
                                            >
                                                <IoCloseOutline />
                                            </button>
                                        </div>
                                    ))}
                            </div>
                        </div>





                    </div>

                </div>

                <div className="mb-4">
                    <label htmlFor="cloneSize" className="block text-black-700 font-bold mb-1">
                        Clone Size:
                    </label>
                    <input
                        type="number"
                        id="cloneSize"
                        name="cloneSize"
                        value={experiment?.clone_size ?? ""}
                        onChange={(e) => {
                            handleInputChange({ clone_size: e?.target?.value })
                        }
                        }
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="cloningSites" className="block text-black-700 font-bold mb-1">
                        Cloning Sites:
                    </label>
                    <textarea
                        id="cloningSites"
                        name="cloningSites"
                        value={experiment?.cloning_sites ?? ""}
                        onChange={(e) => {
                            handleInputChange({ cloning_sites: e?.target?.value })
                        }
                        }
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>


                {/* Actions */}
                <div className="flex justify-end">
                    <button
                        type="button"
                        className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={handleSave}
                        className="p-2 w-24 font-bold text-white rounded"
                        style={{ background: '#7FB541', borderColor: 'transparent' }}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>

    );
};

export default VirtualCloneConstruct;
