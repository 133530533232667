import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { changePassword } from '../../slices/password';
import { USER_MSG } from '../../utils/messages';
import { SERVICE_STATUS } from '../../utils/types';
import { validatePassword } from '../../utils/validation';
import ImageLoader from '../ImageLoader';

const ChangeProfilePassword = () => {
  const dispatch = useAppDispatch();
  const passwordStatus = useAppSelector((state) => state.password.status);
  const [inputPassword, setInputPassword] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [passwordError, setPasswordError] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  

  const onInputPasswordChange = (event) => {
    const { name, value } = event.target;
    setInputPassword((prev) => ({ ...prev, [name]: value }));
    validateInput(name, value);
  };

  const validateInput = (name, value) => {
    setPasswordError((prev) => {
      const stateObj = { ...prev, [name]: '' };

      switch (name) {
        case 'currentPassword':
          if (!value) {
            stateObj['currentPassword'] = USER_MSG.ENTER_CURRENT_PASSWORD;
          }
          break;

        case 'newPassword':
          const validPass = validatePassword(value);
          if (!validPass.isValidPassword) {
            stateObj['newPassword'] = validPass.passwordErrorMsg;
          } else if (
            inputPassword.confirmPassword &&
            value !== inputPassword.confirmPassword
          ) {
            stateObj['confirmPassword'] = USER_MSG.PASSWORD_NO_MATCH;
          } else {
            stateObj['confirmPassword'] = inputPassword.confirmPassword
              ? ''
              : passwordError && passwordError.confirmPassword;
          }

          stateObj['newPassword'] = validPass.passwordErrorMsg;
          if (inputPassword.currentPassword) {
            stateObj['currentPassword'] = '';
          }
          break;

        case 'confirmPassword':
          if (!value) {
            stateObj['confirmPassword'] = USER_MSG.ENTER_CONFIRM_PASSWORD;
          } else if (
            inputPassword.newPassword &&
            value !== inputPassword.newPassword
          ) {
            stateObj['confirmPassword'] = USER_MSG.PASSWORD_NO_MATCH;
          }
          if (inputPassword.currentPassword) {
            stateObj['currentPassword'] = '';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    let isEmptyFound = false;

    Object.keys(inputPassword).map((key) => {
      if (!inputPassword[key]) {
        isEmptyFound = true;
        validateInput(key, inputPassword[key]);
      }
    });

    if (isEmptyFound) return false;

    if (
      passwordError &&
      (passwordError.currentPassword ||
        passwordError.newPassword ||
        passwordError.confirmPassword)
    ) {
      return false;
    }

    dispatch(changePassword(inputPassword));
  };

  return (
    <section className="middle-wrapper">
      <div className="container-fluid">
        <form onSubmit={handleChangePassword}>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <ul className="breadcrumb-div">
                <li>
                  <Link to="/ProductPage">
                    <ImageLoader className="imgFirst" name="home.svg" />
                  </Link>
                </li>
                <li>Change Password</li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="main-title">
                <h1>Change Password</h1>
                <div className="rightPanel">
                  <ul className="action-btn-n">
                    <li>
                      <button
                        className="active btn btnNormal"
                        type="submit"
                        disabled={passwordStatus === SERVICE_STATUS.INPROGRESS}
                      >
                        Update Password
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-container">
            <div className="profileForm passwordSection">
              <div className="profileCol-right">
                {/* <h2>Change Password</h2> */}
                <ul className="profile-Listing">
                  <li>
                    <div className="halfCol">
                      <label htmlFor="currentPassword">
                        Current Password *
                      </label>
                      <input
                        type="password"
                        name="currentPassword"
                        onChange={onInputPasswordChange}
                        onBlur={(e) =>
                          validateInput(e.target.name, e.target.value)
                        }
                        className={
                          passwordError &&
                          passwordError.currentPassword &&
                          'error-Danger'
                        }
                      />
                      {passwordError && passwordError.currentPassword && (
                        <p className="error-msz">
                          {passwordError.currentPassword}
                        </p>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="halfCol">
                      <label htmlFor="newPassword">New Password *</label>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        name="newPassword"
                        onChange={onInputPasswordChange}
                        onBlur={(e) =>
                          validateInput(e.target.name, e.target.value)
                        }
                        className={
                          passwordError &&
                          passwordError.newPassword &&
                          'error-Danger'
                        }
                      />
                      <strong
                        className="eyeIcon"
                        onClick={() => setShowPassword(!showPassword)}
                        aria-hidden
                      >
                        <ImageLoader className="eye-icon" name="eye.svg" />
                      </strong>
                      {passwordError && passwordError.newPassword && (
                        <p className="error-msz">{passwordError.newPassword}</p>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="halfCol">
                      <label htmlFor="confirmPassword">
                        Confirm New Password *
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        onChange={onInputPasswordChange}
                        onBlur={(e) =>
                          validateInput(e.target.name, e.target.value)
                        }
                        className={
                          passwordError &&
                          passwordError.confirmPassword &&
                          'error-Danger'
                        }
                      />
                      {passwordError && passwordError.confirmPassword && (
                        <p className="error-msz">
                          {passwordError.confirmPassword}
                        </p>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="passwordInfo-block">
                <div className="passwordInfo">
                  <h3>Password Must:</h3>
                  <ul className="password-list">
                    <li>{USER_MSG.PASSWORD_LENGTH}</li>
                    {/* <li>{USER_MSG.PASSWORD_ALPHABETS} </li> */}
                    <li>{USER_MSG.PASSWORD_NUMBER}</li>
                    <li>{USER_MSG.PASSWORD_NO_SPACE}</li>
                    <li>{USER_MSG.PASSWORD_SPECIAL_CHAR}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ChangeProfilePassword;
